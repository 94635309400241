import {Link, useSearchParams} from "react-router-dom";
import {Button, Form, Input, List, Skeleton, Space, Typography} from "antd";
import {useState} from "react";
import axios from "axios";
import '../styles/Welcome.css';
import {connect} from "react-redux";
import {getMe} from "../actions/users";

const askTokenForm = (tokenInput, setTokenInput, checkToken) => {
    return (
        <Form className={'ask-token'}>
            <Form.Item>
                <Typography.Title level={6}>Добро пожаловать</Typography.Title>
                <Typography.Paragraph>Для работы нашего сервиса вам необходимо ввести токен авторизации. Токен вы можете получить <a href={process.env.REACT_APP_BOT_URL} target={'_blank'}>в нашем боте</a> по команде /token</Typography.Paragraph>
            </Form.Item>
            <Form.Item>
                <Input
                    placeholder={'Введите токен здесь'}
                    value={tokenInput}
                    onChange={(e) => setTokenInput(e.target.value)}
                />
            </Form.Item>
            <Form.Item>
                <Button onClick={checkToken} type={'primary'}>Подтвердить</Button>
            </Form.Item>
        </Form>
    )
}

function Welcome ({ getMe, user }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [tokenInput, setTokenInput] = useState('');
    const [loading, setLoading] = useState(true);

    const checkToken = () => {
        setLoading(true);
        getMe(tokenInput);
    }

    if (user === null) {
        if (searchParams.get('token') !== null) {
            getMe(searchParams.get('token'));
        } else {
            return askTokenForm(tokenInput, setTokenInput, checkToken);
        }
    } else if (loading === true) {
        setLoading(false);
    }

    if (loading === true) {
        return <Skeleton active/>
    }

    const links = [
        {
            route: '/tasks',
            text: 'Мои задачи'
        },
        {
            route: '/offers',
            text: 'Отклики на мои задачи'
        },
        {
            route: '/messenger',
            text: 'Мессенджер'
        }
    ]

    return (
        <div>
            <Typography.Title level={4}>Добро пожаловать, {user.full_name}!</Typography.Title>
            <List
                dataSource={links}
                renderItem={(item) => (
                    <List.Item>
                        <Link to={`${item.route}?token=${tokenInput}`}>{item.text}</Link>
                    </List.Item>
                )}
            >

            </List>
        </div>
    )
}

const mapStateToProps = state => ({
    user: state.users.user
});

export default connect(mapStateToProps, {getMe})(Welcome);