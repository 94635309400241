import {ARCHIVATE_TASK_FAIL, GET_ME_FAIL, GET_ME_SUCCESS} from "./types";
import axios from "axios";

export const getMe = (token) => async dispatch => {
    const config = {
        params: {
            token: token
        }
    }

    if (config.params.token === null) {
        // TODO: unauthorized
        dispatch({
            type: GET_ME_FAIL
        });
    }

    try {
        const res = await axios.get(`${process.env.REACT_APP_DTASKBOT_API_URL}/users`, config)
        if (res.status === 200) {
            dispatch({
                type: GET_ME_SUCCESS,
                payload: res.data
            })
        }
    } catch (err) {
        console.log(`ERROR WHILE GETTING ME ${err}`)
        if (err.response.status === 401) {
            // TODO: handle 401
        }
        dispatch({
            type: GET_ME_FAIL
        });
    }
}