import {GET_ME_FAIL, GET_ME_SUCCESS} from "../actions/types";

const initialState = {
    user: null
}

export default function(state=initialState, action) {
    switch (action.type) {
        case GET_ME_SUCCESS:
            return {
                ...state,
                user: action.payload
            }
        case GET_ME_FAIL:
        default:
            return {...state}
    }
}