export const OFFERS_FETCH_SUCCESS = 'OFFERS_FETCH_SUCCESS';
export const OFFERS_FETCH_FAIL = 'OFFERS_FETCH_FAIL';
export const OFFER_REJECT_FAIL = 'OFFER_REJECT_FAIL';
export const OFFER_REJECT_SUCCESS = 'OFFER_REJECT_SUCCESS';
export const OFFER_ACCEPT_SUCCESS = 'OFFER_ACCEPT_SUCCESS';
export const OFFER_ACCEPT_FAIL = 'OFFER_ACCEPT_FAIL';
export const TASKS_FETCH_SUCCESS = 'TASKS_FETCH_SUCCESS';
export const TASKS_FETCH_FAIL = 'TASKS_FETCH_FAIL';
export const ACCEPT_WORK_SUCCESS = 'ACCEPT_WORK_SUCCESS';
export const ACCEPT_WORK_FAIL = 'ACCEPT_WORK_FAIL';
export const ARCHIVATE_TASK_SUCCESS = 'ARCHIVATE_TASK_SUCCESS';
export const ARCHIVATE_TASK_FAIL = 'ARCHIVATE_TASK_FAIL';
export const RESTART_TASK_SUCCESS = 'RESTART_TASK_SUCCESS';
export const RESTART_TASK_FAIL = 'RESTART_TASK_FAIL';
export const CANCEL_TASK_SUCCESS = 'CANCEL_TASK_SUCCESS';
export const CANCEL_TASK_FAIL = 'CANCEL_TASK_FAIL';
export const GET_ME_SUCCESS = 'GET_ME_SUCCESS';
export const GET_ME_FAIL = 'GET_ME_FAIL';
export const FETCH_DIALOGS_SUCCESS = 'FETCH_DIALOGS_SUCCESS';
export const FETCH_DIALOGS_FAIL = 'FETCH_DIALOGS_FAIL';
export const FETCH_CHAT_MESSAGES_SUCCESS = 'FETCH_CHAT_MESSAGES_SUCCESS';
export const FETCH_CHAT_MESSAGES_FAIL = 'FETCH_CHAT_MESSAGES_FAIL';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_FAIL = 'SEND_MESSAGE_FAIL';